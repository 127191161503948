'use client';

import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import Activity from './components/activity';
import CommunityHosts from './components/community-host';
import Events from './components/events/events';
import Landing from './components/landing/landing';
import FindMoments from './components/moments';
import OurMission from './components/our-mission';

const KnowUs = dynamic(() => import('./components/know-us/know-us'), { ssr: false });

const RootDiv = styled.div`
  overflow: hidden;
`;

export const Homepage = (): React.ReactElement => {
  return (
    <RootDiv>
      <Landing />
      <Activity />
      <Events />
      {/* <EventsCustom /> */}
      <KnowUs />
      <CommunityHosts />
      <FindMoments />
      <OurMission />
    </RootDiv>
  );
};

export default Homepage;
